<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Pievienot izvēles lauku"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Lauks tika veiksmīgi pievienots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p>{{ errorMessage }}</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data">

            <b-form-group
                id="input-group-1"
                label="Lauka veids:"
                label-for="input-1"
            >

              <b-form-select v-model="form.type" :options="options" required></b-form-select>

            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Nosaukums:"
                label-for="input-2"
            >

              <b-form-input v-model="form.value"></b-form-input>

            </b-form-group>

            <b-form-group
                v-if="form.type === 12"
                label="Struktūrvienība:"
                id="input-group-1"
                label-for="input-1"
            >

              <b-form-select v-model="form.unit" :options="units" required></b-form-select>

            </b-form-group>

            <b-button type="submit" variant="success">Saglabāt</b-button>
            <b-button type="submit" variant="warning" class="ml-3" :to="{ name: 'OptionsList' }">Atpakaļ</b-button>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "Create",
  data() {
    return {
      created: null,
      errorMessage: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        type: null,
        value: null,
        unit: null
      },
      fields : [
        { key: 'text', label: 'Tips' },
      ],
      options: [
        { text: 'Dokumenta tips', value: 0 },
        { text: 'Izglītības veids', value: 1 },
        { text: 'Mācību iestāde', value: 2 },
        { text: 'Fakultāte', value: 3 },
        { text: 'Nodaļas', value: 4 },
        { text: 'Grādi', value: 5 },
        { text: 'Kvalifikācijas', value: 6 },
        { text: 'Specialitātes', value: 7 },
        { text: 'Kursi', value: 9 },
        { text: 'Tēmas', value: 10 },
        { text: 'Programmas', value: 11 },
        { text: 'Telpas/vietas', value: 12 },
        { text: 'Struktūrvienības', value: 13 },
      ],
      units: []
    }
  },
  created () {
    axios.get('/options').then(response => {
      this.units = response.data.data.units.data
    })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      axios.post('/options', this.form).then(response => {
        this.created = 'success';

        this.form.value = null;
        this.form.type = null;
        this.form.unit = null;
      }).catch(error => {
        this.created = 'error';
        this.errorMessage = error;
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  }
}
</script>

<style scoped>

</style>
